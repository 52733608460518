$names: ('Light' 'Regular' 'Medium' 'SemiBold' 'Bold');
$weights: (300     400       500      600        700  );


// Straight generation
@for $i from 1 through length($names) {
    $current-name: nth($names, $i);
    $current-weight: nth($weights, $i);
    @font-face {
        font-family: 'HKGrotesk';
        src: url('/fonts/HKGrotesk-#{$current-name}.otf');
        src: url('/fonts/HKGrotesk-#{$current-name}.eot?#iefix') format('embedded-opentype'),
             url('/fonts/HKGrotesk-#{$current-name}.woff2') format('woff2'),
             url('/fonts/HKGrotesk-#{$current-name}.ttf') format('truetype');
        font-weight: $current-weight;
        font-style: normal;
    }
}