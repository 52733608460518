/* purgecss start ignore */
.custom-tooltip {
  color: #F84601!important;
  font-weight: 700!important;
  font-family: HKGrotesk, Roboto!important;
  background-color: #FFDACC!important;
  &.place-top {
    &:after {
      border-top-color: #FFDACC !important;
      border-top-style: solid !important;
      border-top-width: 6px !important;
    }
  }
  &.place-left {
    &:after {
      border-top-color: #FFDACC !important;
      border-top-style: solid !important;
      border-top-width: 6px !important;
    }
  }
  &.place-right {
    &:after {
      border-top-color: #FFDACC !important;
      border-top-style: solid !important;
      border-top-width: 6px !important;
    }
  }
  &.place-bottom {
    &:after {
      border-bottom-color: #FFDACC !important;
      border-bottom-style: solid !important;
      border-bottom-width: 6px !important;
    }
  }
}
/* purgecss end ignore */